import { DateTime } from 'luxon'

const dateTimeFromUtc = date => {
  return date ? DateTime.fromISO(date.replace('Z', '')) : null
}

const dateTimeToUtc = date => {
  return date ? DateTime.fromISO(date.toISOString()).toISO({ includeOffset: false }) + 'Z' : null
}

const jsDateFromUtc = date => {
  return date ? dateTimeFromUtc(date).toJSDate() : null
}

const jsDateToUtc = date => {
  return date ? DateTime.fromISO(date.toISOString()).toISO({ includeOffset: false }) + 'Z' : null
}

export { dateTimeFromUtc, dateTimeToUtc, jsDateFromUtc, jsDateToUtc }
