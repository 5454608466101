<template>
  <div class="grid p-fluid">
    <div class="p-col-12">
      <DataTable :value="dataSources.data.dataSetsValues" dataKey="date" stripedRows
                 :paginator="true" :rows="dataSources.data.rows" :rowsPerPageOptions="dataSources.data.rowsOptions"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :currentPageReportTemplate="t('autocbm.projects.management.data.load.ranges.form.pagination')">
        <Column field="date" :header="t('autocbm.projects.management.data.load.ranges.form.label.date')" headerStyle="width: 8em">
          <template v-if="dataSources.data.dataSetsLoading" #body>
            <Skeleton height="1.25rem" width="10rem" />
          </template>
        </Column>
        <Column field="variables" :header="t('autocbm.projects.management.data.load.ranges.form.label.variablesCount')">
          <template v-if="dataSources.data.dataSetsLoading" #body>
            <Skeleton height="1.25rem" width="5rem" />
          </template>
          <template v-else #body="{ data }">
            {{ data.variables.length }}
          </template>
        </Column>
        <Column field="variables" :header="t('autocbm.projects.management.data.load.ranges.form.label.variable', 2)">
          <template v-if="dataSources.data.dataSetsLoading" #body>
            <Skeleton height="1.25rem" />
          </template>
          <template v-else #body="{ data }">
            {{ data.variables.join(', ') }}
          </template>
        </Column>
        <Column field="measuringPointsCount" :header="t('autocbm.projects.management.data.load.ranges.form.label.measuringPointsCount')">
          <template v-if="dataSources.data.dataSetsLoading" #body>
            <Skeleton height="1.25rem" width="5rem" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMessage } from '@/dockone/app/message'

export default {
  name: 'DataSetsOverview',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const message = useMessage()

    const sourceData = reactive({
      filteredDataSets: computed(() => store.getters['autocbm/data/filteredDataSets']),
      dataSetsLoading: false,
      dataSetsValues: computed(() => {
        if (sourceData.filteredDataSets && sourceData.filteredDataSets.data) {
          return sourceData.filteredDataSets.data
        } else if (sourceData.dataSetsLoading) {
          return new Array(sourceData.rows)
        } else {
          return null
        }
      }),
      rows: 5,
      rowsOptions: [5, 10, 15]
    })
    const sourceDataActions = {
      loadDataSets (sourceId, measurement, startDateTime, endDateTime) {
        sourceData.dataSetsLoading = true
        return store.dispatch('autocbm/data/loadFilteredDataSets', { sourceId, measurement, startDateTime, endDateTime })
            .catch(() => message.error(t('autocbm.projects.management.data.load.ranges.action.load.error.summary'),
                t('autocbm.projects.management.data.load.ranges.action.load.error.detail')))
            .finally(() => sourceData.dataSetsLoading = false)
      }
    }
    const dataSources = { data: sourceData, ...sourceDataActions }

    const projectData = reactive({
      dataSource: computed(() => store.getters['autocbm/project/dataSource']),
      measurement: computed(() => store.getters['autocbm/project/measurement']),
      startDateTime: computed(() => store.getters['autocbm/project/startDateTime']),
      endDateTime: computed(() => store.getters['autocbm/project/endDateTime'])
    })
    const project = { data: projectData }

    watch([() => project.data.startDateTime, () => project.data.endDateTime], ([startDateTime, endDateTime]) => {
      if (startDateTime && endDateTime) {
        dataSources.loadDataSets(project.data.dataSource, project.data.measurement, startDateTime, endDateTime)
      }
    }, { immediate: true })

    return { t, dataSources, project }
  }
}
</script>
