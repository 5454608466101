<template>
  <div class="tree-select">
    <div class="p-dropdown-breadcrumb p-breadcrumb p-dropdown p-component p-inputwrapper p-inputwrapper-filled"
         :class="{ focus: data.overlay, 'p-disabled': disabled && !loading }" @click="toggle">
      <Skeleton v-if="loading" height="1.25rem" />
      <ul v-else class="p-dropdown-label">
        <li class="p-breadcrumb-home p-breadcrumb-item">
          <i class="p-menuitem-icon" :class="icon"></i>
          <span v-if="label" class="p-menuitem-link ml-2">{{ label }}</span>
        </li>
        <template v-for="item in data.selectedValues" :key="item[optionKey]">
          <li class="p-breadcrumb-chevron pi pi-chevron-right"></li>
          <li class="p-breadcrumb-item">
            <span class="p-menuitem-link">{{ item[optionLabel] }}</span>
          </li>
        </template>
      </ul>
      <div class="p-breadcrumb-chevron p-breadcrumb-item p-dropdown-trigger ml-3" style="margin-left: auto">
        <i v-if="data.selected" class="pi pi-times mr-3" @click.stop="clear"></i>
        <i class="pi pi-chevron-down"></i>
      </div>
    </div>
    <OverlayPanel ref="selectionComponent" :breakpoints="{'960px': '75vw', '640px': '100vw'}" style="width: 450px">
      <ScrollPanel v-if="options" :style="data.overlayScrollStyle">
        <div class="grid formgrid p-fluid">
          <div class="col-12">
            <TreeSelectListBox :modelValue="data.selectionValues" :options="options"
                               :optionKey="optionKey" :optionLabel="optionLabel" :optionValue="optionValue"
                               :optionGroupKey="optionGroupKey" :optionGroupLabel="optionGroupLabel" :optionGroupValues="optionGroupValues"
                               @update:modelValue="updateSelection" @leaf="onLeaf" />
          </div>
        </div>
      </ScrollPanel>
      <div class="grid mt-2 p-fluid">
        <div class="col-6">
          <Button class="p-button-primary p-button-outlined" @click="clear">
            <span class="ml-2">{{ t('app.form.label.cancel') }}</span>
            <span class="text-right width-100"><i class="pi pi-times" /></span>
          </Button>
        </div>
        <div class="col-6">
          <Button class="p-button-success" @click="submit" :disabled="!data.selectionIsLeaf">
            <span class="ml-2">{{ t('app.form.label.confirm') }}</span>
            <span class="text-right width-100"><i class="pi pi-check" /></span>
          </Button>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import TreeSelectListBox from './TreeSelectListBox'
import { reactive, ref, computed, watch, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import _ from 'lodash'

export default {
  name: 'TreeSelect',
  components: { TreeSelectListBox },
  props: {
    icon: { type: String, default: 'pi pi-home' },
    label: { type: String, default: null },
    options: { type: Object, default: () => {} },
    optionKey: { type: String, default: 'key' },
    optionLabel: { type: String, default: 'label' },
    optionValue: { type: String, default: 'value' },
    optionGroupKey: { type: String, default: 'key' },
    optionGroupLabel: { type: String, default: 'label' },
    optionGroupValues: { type: String, default: 'values' },
    modelValue: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: null },
    loading: { type: Boolean, default: null }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const { options, modelValue } = toRefs(props)
    const selectionComponent = ref()

    const adaptModelValue = (options, modelValue) => {
      const selectedValues = []

      let currentLevel = options
      for (const value of modelValue) {
        const groupKey = currentLevel[props.optionGroupKey]
        const groupLabel = currentLevel[props.optionGroupLabel]

        if (currentLevel[props.optionGroupKey] === value.groupKey && currentLevel[props.optionGroupValues]) {
          const index = _.findIndex(currentLevel[props.optionGroupValues], [props.optionKey, value[props.optionKey]])
          if (~index) {
            const item = currentLevel[props.optionGroupValues][index]
            const key = item[props.optionKey]
            const label = item[props.optionLabel]
            currentLevel = item[props.optionValue]

            selectedValues.push({ groupKey, groupLabel, key, label })
          }
        }
      }

      return selectedValues
    }

    const data = reactive({
      selectionValues: [],
      selectionIsLeaf: false,
      selectedValues: computed(() => {
        if (options.value && modelValue.value) {
          return adaptModelValue(options.value, modelValue.value)
        }
        return []
      }),
      selected: computed(() => data.selectedValues.length > 0),
      overlay: computed(() => selectionComponent.value && selectionComponent.value.visible),
      overlayScrollStyle: computed(() => ({
        width: '100%',
        height: `calc(100vh / 3)`
      }))
    })

    const actions = {
      updateSelection (selectionValues) {
        data.selectionValues = adaptModelValue(options.value, selectionValues)
      },
      onLeaf (value) {
        data.selectionIsLeaf = value
      },
      toggle (event) {
        if (!props.disabled) {
          selectionComponent.value.toggle(event)
        }
      },
      hide () {
        selectionComponent.value.hide()
      },
      clear () {
        if (!props.disabled) {
          emit('update:modelValue', [])
          actions.hide()
        }
      },
      submit () {
        if (!props.disabled) {
          actions.hide()
          emit('update:modelValue', data.selectionValues.map(({ groupKey, key }) => ({ groupKey, key })))
        }
      }
    }

    watch(() => data.selectedValues, selectedValues => data.selectionValues = selectedValues, { immediate: true })

    return { t, selectionComponent, data, ...actions }
  }
}
</script>

<style lang="scss" scoped>
.tree-select {
  width: 100%;

  .p-dropdown-breadcrumb.p-component {
    width: 100%;
    cursor: pointer;

    &.focus {
      border-color: var(--primary-color);
    }
  }
}
</style>
