<template>
  <Dialog :header="t('autocbm.projects.management.data.load.sources.upload.title')" modal :visible="visible"
          @update:visible="$emit('update:visible', $event)" :style="{ width: '50vw' }" class="data-source-upload">
    <div class="formgrid grid p-fluid mt-3 mb-3">
      <div class="col-12">
        <div class="p-field">
          <div class="p-inputgroup">
            <span class="p-float-label">
              <InputText type="text" id="datasourceName" v-model="v$.datasourceName.$model" :class="{ 'p-invalid': v$.datasourceName.$invalid }" />
              <label for="datasourceName" :class="{ 'p-error': v$.datasourceName.$invalid }">
                {{ t('autocbm.projects.management.data.load.sources.upload.form.label.dataSourceName') }}
              </label>
              <InlineMessage v-if="v$.datasourceName.$invalid" class="ml-1" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <FileUpload name="datasource" accept="application/json,application/vnd.ms-excel,text/csv" :maxFileSize="1000000 * 500" :multiple="true"
                :chooseLabel="t('app.form.label.choose')" :uploadLabel="t('app.form.label.upload')" :cancelLabel="t('app.form.label.cancel')"
                :showUploadButton="uploadForm.uploadPossible" :url="requestData.uploadUrl"
                @select="select" @before-send="upload" @progress="uploadProgress" @upload="uploadSuccess" @error="uploadError" @clear="cancel" />
    <div class="messages mt-3 mb-3">
      <transition-group name="fade" mode="out-in">
        <Message v-if="!uploadForm.dataStructureFile || uploadForm.selectedFiles > 2" severity="warn" :closable="false">
          {{ t('autocbm.projects.management.data.load.sources.upload.form.message.dataStructure') }}
        </Message>
        <Message v-if="!uploadForm.dataPointsFile || uploadForm.selectedFiles > 2" severity="warn" :closable="false">
          {{ t('autocbm.projects.management.data.load.sources.upload.form.message.dataPoints') }}
        </Message>
        <Message v-if="uploadForm.selectedFiles > 2" severity="error" :closable="false">
          {{ t('autocbm.projects.management.data.load.sources.upload.form.message.selectedFiles') }}
        </Message>
      </transition-group>
    </div>
  </Dialog>
</template>

<script>
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useMessage } from '@/dockone/app/message'
import { find } from 'lodash/collection'
import { endsWith } from 'lodash/string'

export default {
  name: 'DataUpload',
  props: {
    visible: { type: Boolean }
  },
  emits: ['update:visible', 'upload'],
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()
    const message = useMessage()

    const rules = {
      datasourceName: { required }
    }
    const uploadForm = reactive({
      datasourceName: '',
      files: [],
      dataStructureFile: computed(() => find(uploadForm.files, file => endsWith(file.name, '.json'))),
      dataPointsFile: computed(() => find(uploadForm.files, file => endsWith(file.name, '.csv'))),
      selectedFiles: computed(() => uploadForm.files.length),
      uploadPossible: computed(() => uploadForm.selectedFiles === 2 && !!uploadForm.dataStructureFile && !!uploadForm.dataPointsFile),
      loading: false,
      progress: null
    })
    const v$ = useVuelidate(rules, uploadForm)

    const requestData = reactive({
      uploadUrl: computed(() => process.env.APP_BASE_URL + process.env.APP_SERVICE_URL + '/api/management/data/import/' + uploadForm.datasourceName),
      accessToken: computed(() => store.getters['authentication/accessToken'])
    })

    const actions = {
      cancel () {
        uploadForm.datasourceName = ''
        uploadForm.files = []
      },
      select ({ files }) {
        uploadForm.files = files
      },
      upload ({ xhr, formData }) {
        xhr.setRequestHeader('Authorization', `Bearer ${requestData.accessToken}`)
        if (formData.has('datasource')) {
          const files = formData.getAll('datasource')
          const dataStructure = find(files, file => endsWith(file.name, '.json'))
          const dataPointsFile = find(files, file => endsWith(file.name, '.csv'))
          if (dataStructure && dataPointsFile) {
            formData.append('data_source', dataStructure)
            formData.append('data_points', dataPointsFile)
            formData.delete('datasource')
          }
        }
        uploadForm.loading = true
      },
      uploadProgress ({ progress }) {
        uploadForm.progress = progress
      },
      uploadSuccess () {
        actions.cancel()
        message.success(t('autocbm.projects.management.data.load.sources.upload.action.upload.success.summary'),
            t('autocbm.projects.management.data.load.sources.upload.action.upload.success.detail'))
        emit('update:visible', false)
        emit('upload')
      },
      uploadError () {
        actions.cancel()
        message.error(t('autocbm.projects.management.data.load.sources.upload.action.upload.error.summary'),
            t('autocbm.projects.management.data.load.sources.upload.action.upload.error.detail'))
        emit('update:visible', false)
      }
    }

    return { t, v$, uploadForm, requestData, ...actions }
  }
}
</script>

<style lang="scss">
.data-source-upload .p-fileupload .p-fileupload-content {
  .p-progressbar {
    height: 1rem;
  }
}
</style>
