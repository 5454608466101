<template>
  <div class="grid formgrid p-fluid">
    <div class="field col-12 md:col-6 lg:col-3">
      <DispatchInputWrapper :disabled="project.data.disabled" :loading="dataSources.data.dataSetsLoading" :saving="project.data.startDateTimeSaving"
                            :saved="project.data.startDateTimeSaved" :error="project.data.startDateTimeError">
        <div v-if="dataSources.data.dataSetsLoading" class="p-inputgroup">
          <Skeleton height="3rem" />
          <span class="p-inputgroup-addon p-disabled"><i class="pi pi-calendar"></i></span>
        </div>
        <span v-else class="p-float-label">
          <Calendar id="startDatetime" v-model="project.data.inputStartDateTime"
                    :minDate="project.data.minDateTime" :maxDate="project.data.inputEndDateTime"
                    :showTime="true" hourFormat="24" dateFormat="dd.mm.yy" :selectOtherMonths="true" :manualInput="false"
                    :showIcon="true" :disabled="project.data.disabled">
            <template #date="{ date }">
              <strong v-if="dataSources.dateAvailable(date)" class="data-available">{{ date.day }}</strong>
              <template v-else> {{ date.day }}</template>
            </template>
          </Calendar>
          <label for="startDatetime">{{ t('autocbm.projects.management.data.load.ranges.form.label.from') }}</label>
        </span>
      </DispatchInputWrapper>
    </div>
    <div class="field col-12 md:col-6 lg:col-3">
      <DispatchInputWrapper :disabled="project.data.disabled" :loading="dataSources.data.dataSetsLoading" :saving="project.data.endDateTimeSaving"
                            :saved="project.data.endDateTimeSaved" :error="project.data.endDateTimeError">
        <div v-if="dataSources.data.dataSetsLoading" class="p-inputgroup">
          <Skeleton height="3rem" />
          <span class="p-inputgroup-addon p-disabled"><i class="pi pi-calendar"></i></span>
        </div>
        <span v-else class="p-float-label">
          <Calendar id="endDatetime" v-model="project.data.inputEndDateTime"
                    :minDate="project.data.inputStartDateTime" :maxDate="project.data.maxDateTime"
                    :showTime="true" hourFormat="24" dateFormat="dd.mm.yy" :selectOtherMonths="true" :manualInput="false"
                    :showIcon="true" :disabled="project.data.disabled">
            <template #date="{ date }">
              <strong v-if="dataSources.dateAvailable(date)" class="data-available">{{ date.day }}</strong>
              <template v-else> {{ date.day }}</template>
            </template>
          </Calendar>
          <label for="endDatetime">{{ t('autocbm.projects.management.data.load.ranges.form.label.until') }}</label>
        </span>
      </DispatchInputWrapper>
    </div>
    <div class="field col-12 md:col-6 lg:col-3">
      <div v-if="dataSources.data.dataSetsLoading" class="p-inputgroup">
        <Skeleton height="3rem" />
      </div>
      <span v-else class="p-float-label">
        <InputNumber id="selectedDateCount" v-model="project.data.dayRangeCount" mode="decimal" showButtons :min="1" :max="project.data.dayRangeMaxCount"
                     :suffix="' ' + t('autocbm.projects.management.data.load.ranges.form.label.day', project.data.dayRangeCount)"
                     :disabled="project.data.disabled" @input="project.updateDateTimeWithCount($event.value)" />
        <label for="selectedDateCount">{{ t('autocbm.projects.management.data.load.ranges.form.label.selectedDays') }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-6 lg:col-3">
      <div v-if="dataSources.data.dataSetsLoading" class="p-inputgroup">
        <Skeleton height="3rem" />
      </div>
      <span v-else class="p-float-label">
        <InputNumber id="selectedDataCount" v-model="project.data.dataRangeCount" mode="decimal" showButtons :min="1" :max="project.data.dataRangeMaxCount"
                     :suffix="' ' + t('autocbm.projects.management.data.load.ranges.form.label.data', project.data.dataRangeCount)"
                     :disabled="project.data.disabled" @input="project.updateDateTimeWithDataCount($event.value)" />
        <label for="selectedDataCount">{{ t('autocbm.projects.management.data.load.ranges.form.label.selectedData') }}</label>
      </span>
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useDispatcher } from '@/dockone/components/dispatcher'
import includes from 'lodash/includes'
import { DateTime } from 'luxon'
import { dateTimeFromUtc, jsDateFromUtc, jsDateToUtc } from './date-utc-utils'

export default {
  name: 'SelectDataTimeInterval',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const { dispatches, handleDispatch, handleUpdate } = useDispatcher(['startDateTime', 'endDateTime'])

    const sourceData = reactive({
      dataSets: computed(() => store.getters['autocbm/data/dataSets']),
      dataSetsLoading: false,
      dataSetsInterval: computed(() => sourceData.dataSets && sourceData.dataSets.data ? sourceData.dataSets.data : []),
      minDateTime: computed(() => sourceData.dataSets && sourceData.dataSets.minDateTime ? sourceData.dataSets.minDateTime : null),
      maxDateTime: computed(() => sourceData.dataSets && sourceData.dataSets.maxDateTime ? sourceData.dataSets.maxDateTime : null)
    })
    const sourceDataActions = {
      loadDataSets (measurement) {
        sourceData.dataSetsLoading = true
        const sourceId = project.data.dataSource
        return store.dispatch('autocbm/data/loadDataSets', { sourceId, measurement }).finally(() => sourceData.dataSetsLoading = false)
      },
      dateAvailable (date) {
        const { day, month, year } = date
        const dateTime = DateTime.fromObject({ day, month: month + 1, year }).toISODate()
        return includes(sourceData.dataSetsInterval.map(e => e.date), dateTime)
      }
    }
    const dataSources = { data: sourceData, ...sourceDataActions }

    const projectData = reactive({
      readonly: computed(() => store.getters['autocbm/project/readonly']),
      dataSource: computed(() => store.getters['autocbm/project/dataSource']),
      dataSourceStructure: computed(() => store.getters['autocbm/project/dataSourceStructure']),
      measurement: computed(() => store.getters['autocbm/project/measurement']),
      disabled: computed(() => (!projectData.dataSourceStructure || projectData.dataSourceStructure.length === 0) || !projectData.measurement ||
                               projectData.startDateTimeSaving || projectData.startDateTimeSaving || projectData.readonly),
      minDateTime: computed(() => sourceData.minDateTime ? jsDateFromUtc(sourceData.minDateTime) : null),
      maxDateTime: computed(() => sourceData.maxDateTime ? jsDateFromUtc(sourceData.maxDateTime) : null),
      startDateTime: computed({
        get: () => store.getters['autocbm/project/startDateTime'],
        set: dateTime => handleDispatch('startDateTime', store.dispatch('autocbm/project/selectStartDateTime', dateTime))
      }),
      startDateTimeDispatch: computed(() => dispatches.get('startDateTime')),
      startDateTimeSaving: computed(() => projectData.startDateTimeDispatch.pending > 0),
      startDateTimeSaved: computed(() => !!projectData.startDateTime && projectData.startDateTimeDispatch.success),
      startDateTimeError: computed(() => projectData.startDateTimeDispatch.error),
      inputStartDateTime: computed({
        get: () => jsDateFromUtc(projectData.startDateTime),
        set: dateTime => projectData.startDateTime = jsDateToUtc(dateTime)
      }),
      endDateTime: computed({
        get: () => store.getters['autocbm/project/endDateTime'],
        set: dateTime => handleDispatch('endDateTime', store.dispatch('autocbm/project/selectEndDateTime', dateTime))
      }),
      endDateTimeDispatch: computed(() => dispatches.get('endDateTime')),
      endDateTimeSaving: computed(() => projectData.endDateTimeDispatch.pending > 0),
      endDateTimeSaved: computed(() => !!projectData.endDateTime && projectData.endDateTimeDispatch.success),
      endDateTimeError: computed(() => projectData.endDateTimeDispatch.error),
      inputEndDateTime: computed({
        get: () => jsDateFromUtc(projectData.endDateTime),
        set: dateTime => projectData.endDateTime = jsDateToUtc(dateTime)
      }),
      dayRangeCount: null,
      dayRangeMaxCount: null,
      dataRangeCount: null,
      dataRangeMaxCount: computed(() => sourceData.dataSetsInterval.length)
    })
    const projectActions = {
      initDateTimeRange (minDateTime, maxDateTime) {
        if (minDateTime && !projectData.startDateTime && projectData.measurement) {
          projectData.startDateTime = minDateTime
        }
        if (maxDateTime && !projectData.endDateTime && projectData.measurement) {
          projectData.endDateTime = maxDateTime
        }
      },
      updateDateTimeCount (startDateTime, endDateTime) {
        if (startDateTime && endDateTime && sourceData.maxDateTime) {
          const start = dateTimeFromUtc(startDateTime).startOf('day')
          const end = dateTimeFromUtc(endDateTime).endOf('day')
          const max = dateTimeFromUtc(sourceData.maxDateTime).endOf('day')
          projectData.dayRangeCount = Math.ceil(end.diff(start, 'days').days)
          projectData.dayRangeMaxCount = Math.ceil(max.diff(start, 'days').days)
        } else {
          projectData.dayRangeCount = null
          projectData.dayRangeMaxCount = null
        }
      },
      updateDateTimeWithCount (dayRangeCount) {
        if (dayRangeCount) {
          const start = dateTimeFromUtc(projectData.startDateTime)
          const newEndDateTime = start.plus({ days: dayRangeCount - 1 }).endOf('day')
          const maxDateTime = dateTimeFromUtc(sourceData.maxDateTime)
          projectData.endDateTime = newEndDateTime >= maxDateTime ? maxDateTime.toString() : newEndDateTime.toString()
        }
      },
      updateDateTimeWithDataCount (dataRangeCount) {
        const startDate = dateTimeFromUtc(projectData.startDateTime).startOf('day')
        const filteredDataSets = sourceData.dataSetsInterval
            .map(element => element.date)
            .map(date => DateTime.fromISO(date))
            .filter(date => date >= startDate)
            .slice(0, dataRangeCount)

        if (filteredDataSets.length > 0) {
          const last = filteredDataSets.slice(-1)[0]
          const newEndDateTime = last.endOf('day')
          const maxDateTime = dateTimeFromUtc(sourceData.maxDateTime)
          projectData.endDateTime = newEndDateTime >= maxDateTime ? maxDateTime.toString() : newEndDateTime.toString()
        } else {
          projectData.endDateTime = startDate.endOf('day').toString()
        }
      },
      updateDataCount (dataSetsInterval, startDateTime, endDateTime) {
        const startDate = dateTimeFromUtc(startDateTime).startOf('day')
        const endDate = dateTimeFromUtc(endDateTime).endOf('day')
        const filteredDataSets = dataSetsInterval.map(element => element.date).filter(date => {
          const dateTime = DateTime.fromISO(date)
          return dateTime >= startDate && dateTime <= endDate
        })
        projectData.dataRangeCount = filteredDataSets.length
      }
    }
    const project = { data: projectData, ...projectActions }

    watch([() => dataSources.data.minDateTime, () => dataSources.data.maxDateTime], ([minDateTime, maxDateTime]) => {
      project.initDateTimeRange(minDateTime, maxDateTime)
    }, { immediate: true })
    watch([() => dataSources.data.dataSetsInterval, () => project.data.startDateTime, () => project.data.endDateTime],
        ([dataSetsInterval, startDateTime, endDateTime]) => {
          if (dataSetsInterval && startDateTime && endDateTime) {
            handleUpdate('startDateTime', startDateTime)
            handleUpdate('endDateTime', endDateTime)
            project.updateDateTimeCount(startDateTime, endDateTime)
            project.updateDataCount(dataSetsInterval, startDateTime, endDateTime)
          }
        }, { immediate: true })
    watch([() => project.data.dataSource, () => project.data.measurement], ([dataSource, measurement]) => {
      if (dataSource && measurement) {
        dataSources.loadDataSets(measurement)
      }
    }, { immediate: true })

    return { t, dataSources, project }
  }
}
</script>

<style lang="scss" scoped>
.p-datepicker.p-component {
  .p-datepicker-calendar {
    .data-available {
      color: var(--primary-color)
    }
  }
}
</style>
