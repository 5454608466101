<template>
  <div class="select-list">
    <transition name="fade" mode="out-in">
      <div v-if="selected.data.selectedValuesAvailable" class="pl-1 pr-1 mb-2">
        <Listbox :key="selected.data.currentKey" :options="selected.data.selectedValues" @change="selected.change($event.value)">
          <template #option="{ option }">
            <span class="ml-2"><b>{{ option.groupLabel || option.groupKey }}: </b>{{ option.label || option.key }}</span>
            <span style="float: right"><i class="pi pi-times" /></span>
          </template>
        </Listbox>
      </div>
    </transition>
    <div class="pl-1 pr-1">
      <transition name="fade" mode="out-in">
        <Listbox :key="selection.data.currentKey" v-if="!selection.data.leaf"
                 :options="selection.data.currentTreeLevelOptions" optionLabel="label" optionGroupLabel="label" optionGroupChildren="values"
                 @change="selection.selectData($event.value)">
          <template #empty>
            {{ t('app.form.label.noData') }}
          </template>
          <template #optiongroup="{ option }">
            <b>{{ option.label }}</b>
          </template>
        </Listbox>
      </transition>
    </div>
  </div>
</template>

<script>
import { reactive, computed, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import _ from 'lodash'

export default {
  name: 'TreeSelectListBox',
  props: {
    options: { type: Object, required: true },
    optionKey: { type: String, default: 'key' },
    optionLabel: { type: String, default: 'label' },
    optionValue: { type: String, default: 'value' },
    optionGroupKey: { type: String, default: 'key' },
    optionGroupLabel: { type: String, default: 'label' },
    optionGroupValues: { type: String, default: 'values' },
    modelValue: { type: Array }
  },
  emits: ['update:modelValue', 'leaf'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const { options, modelValue } = toRefs(props)

    const selectedData = reactive({
      selectedValues: [...props.modelValue],
      selectedValuesAvailable: computed(() => selectedData.selectedValues.length > 0),
      currentKey: computed(() => {
        const currentValue = selectedData.selectedValues.slice(-1).pop()
        return currentValue ? 'selected-' + currentValue.groupKey : '-'
      })
    })
    const selectedActions = {
      change (value) {
        if (value && value.groupKey) {
          const { groupKey } = value
          const itemIndex = _.findIndex(selectedData.selectedValues, { groupKey })
          if (~itemIndex) {
            selectedData.selectedValues.splice(itemIndex)
          }
          emit('update:modelValue', selectedData.selectedValues)
        }
      }
    }
    watch(modelValue, value => selectedData.selectedValues = value || [])
    const selected = { data: selectedData, ...selectedActions }

    const selectionData = reactive({
      currentTreeLevel: computed(() => {
        if (options.value) {
          let currentLevel = options.value
          for (const value of selectedData.selectedValues) {
            if (currentLevel[props.optionGroupKey] === value.groupKey && currentLevel[props.optionGroupValues]) {
              const index = _.findIndex(currentLevel[props.optionGroupValues], [props.optionKey, value[props.optionKey]])
              if (~index) {
                currentLevel = currentLevel[props.optionGroupValues][index][props.optionValue]
              }
            }
          }
          return currentLevel
        }
        return {}
      }),
      currentTreeLevelOptions: computed(() => {
        if (selectionData.currentTreeLevel) {
          const current = selectionData.currentTreeLevel
          if (current[props.optionGroupKey] && current[props.optionGroupLabel] && current[props.optionGroupValues]) {
            return [{
              key: current[props.optionGroupKey],
              label: current[props.optionGroupLabel],
              values: current[props.optionGroupValues].map(value => ({
                key: value[props.optionKey],
                label: value[props.optionLabel],
                groupKey: current[props.optionGroupKey],
                groupLabel: current[props.optionGroupLabel]
              }))
            }]
          }
        }
        return []
      }),
      currentKey: computed(() => selectionData.currentTreeLevel ? 'select-' + selectionData.currentTreeLevel[props.optionGroupKey] : '-'),
      leaf: computed(() => !selectionData.currentTreeLevel)
    })
    const selectionActions = {
      selectData (value) {
        if (value && value.key && value.groupKey) {
          const { key, groupKey } = value
          const itemIndex = _.findIndex(selectedData.selectedValues, { groupKey })
          if (~itemIndex) {
            selectedData.selectedValues.splice(itemIndex, selectedData.selectedValues.length, { groupKey, key })
          } else {
            selectedData.selectedValues.push({ groupKey, key })
          }
          emit('update:modelValue', selectedData.selectedValues)
        }
      }
    }
    watch(() => selectionData.leaf, value => emit('leaf', value), { immediate: true })
    const selection = { data: selectionData, ...selectionActions }

    return { t, selected, selection }
  }
}
</script>

<style lang="scss">
.select-list {
  .p-listbox {
    border-color: var(--primary-color);

    &.select-list-selected {
      border: none;

      .p-listbox-item.p-highlight {
        border-radius: 16px;
      }
    }
  }
}
</style>
