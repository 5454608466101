<template>
  <div class="grid formgrid mt-5 mb-5 p-fluid">
    <div class="col-6 md:col-5 lg:col-4 xl:col-4">
      <DispatchInputWrapper :loading="dataSources.data.sourcesLoading" :saving="project.data.dataSourceSaving"
                            :saved="project.data.dataSourceSaved" :error="project.data.dataSourceError">
        <template #default>
          <DataUpload v-model:visible="dataSources.data.uploadVisible" @upload="dataSources.loadSources" />
          <div v-if="dataSources.data.sourcesLoading" class="p-inputgroup">
            <Skeleton height="3rem" />
          </div>
          <span v-else class="p-float-label">
            <Dropdown v-model="project.data.dataSource" :options="dataSources.data.sources" optionLabel="name" optionValue="id" dataKey="id" showClear
                      :disabled="project.data.readonly" />
            <label for="startDatetime">{{ t('autocbm.projects.management.data.load.sources.form.label.source') }}</label>
          </span>
        </template>
        <template #rightInput>
          <Button type="button" icon="pi pi-refresh" class="p-button-primary" :loading="dataSources.data.sourcesLoading" @click="dataSources.loadSources()" />
        </template>
      </DispatchInputWrapper>
    </div>
    <div class="col-6 md:col-3 lg:col-3 xl:col-2 col-3 md:col-4 lg:col-3 xl:col-2">
      <Button :label="t('autocbm.projects.management.data.load.sources.form.label.upload')" icon="pi pi-upload" class="p-button-outlined"
              @click="dataSources.data.uploadVisible = true" />
    </div>
  </div>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
      <DispatchInputWrapper :disabled="!project.data.dataSource" :loading="dataSources.data.sourcesLoading"
                            :saving="project.data.dataSourceStructureSaving"
                            :saved="project.data.dataSourceStructureSaved" :error="project.data.dataSourceStructureError">
        <template #default>
          <TreeSelect icon="pi pi-folder" :label="t('autocbm.projects.management.data.load.sources.form.label.devices')"
                      v-model="project.data.dataSourceStructure" :options="dataSources.data.sourceStructureOptions"
                      optionKey="key" optionLabel="label" optionValue="group"
                      optionGroupKey="key" optionGroupLabel="label" optionGroupValues="items"
                      :disabled="!dataSources.data.sourceStructure || project.data.readonly" :loading="dataSources.data.sourcesLoading" />
        </template>
      </DispatchInputWrapper>
    </div>
  </div>
</template>

<script>
import DataUpload from './DataUpload'
import TreeSelect from '@/dockone/components/treeselect/TreeSelect'

import { reactive, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useDispatcher } from '@/dockone/components/dispatcher'
import { useMessage } from '@/dockone/app/message'

export default {
  name: 'LoadDataSources',
  components: { DataUpload, TreeSelect },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const { dispatches, handleDispatch, handleUpdate } = useDispatcher(['dataSource', 'dataSourceStructure'])
    const message = useMessage()

    const sourceData = reactive({
      uploadVisible: false,
      sources: computed(() => store.getters['autocbm/data/sources'] || []),
      sourcesLoading: false,
      sourceStructure: computed(() => store.getters['autocbm/data/sourceStructure']),
      sourceStructureOptions: computed(() => sourceData.sourceStructure ? sourceData.sourceStructure.root : null),
      sourceStructureLoading: false
    })
    const sourceDataActions = {
      loadSources () {
        sourceData.sourcesLoading = true
        return store.dispatch('autocbm/data/loadSources')
            .catch(() => message.error(t('autocbm.projects.management.data.load.sources.action.loadSources.error.summary'),
                t('autocbm.projects.management.data.load.sources.action.loadSources.error.detail')))
            .finally(() => sourceData.sourcesLoading = false)
      },
      loadSourceStructure (sourceId) {
        sourceData.sourceStructureLoading = true
        return store.dispatch('autocbm/data/loadSourceStructure', { sourceId })
            .catch(() => message.error(t('autocbm.projects.management.data.load.sources.action.loadSourceStructure.error.summary'),
                t('autocbm.projects.management.data.load.sources.action.loadSourceStructure.error.detail')))
            .finally(() => sourceData.sourceStructureLoading = false)
      },
      clearSourceStructure () {
        return store.dispatch('autocbm/data/clearSourceStructure')
      }
    }
    const dataSources = { data: sourceData, ...sourceDataActions }

    const projectData = reactive({
      dataSource: computed({
        get: () => store.getters['autocbm/project/dataSource'],
        set: sourceId => handleDispatch('dataSource', store.dispatch('autocbm/project/selectDataSource', sourceId))
      }),
      dataSourceDispatch: computed(() => dispatches.get('dataSource')),
      dataSourceSaving: computed(() => projectData.dataSourceDispatch.pending > 0),
      dataSourceSaved: computed(() => !!projectData.dataSource && projectData.dataSourceDispatch.success),
      dataSourceError: computed(() => projectData.dataSourceDispatch.error),
      dataSourceStructure: computed({
        get: () => store.getters['autocbm/project/dataSourceStructure'],
        set: sourceStructure => handleDispatch('dataSourceStructure', store.dispatch('autocbm/project/selectDataSourceStructure', {
          sourceStructure,
          sourceStructureOptions: sourceData.sourceStructureOptions
        }))
      }),
      dataSourceStructureSet: computed(() => !!projectData.dataSourceStructure && projectData.dataSourceStructure.length > 0),
      dataSourceStructureDispatch: computed(() => dispatches.get('dataSourceStructure')),
      dataSourceStructureSaving: computed(() => projectData.dataSourceStructureDispatch.pending > 0),
      dataSourceStructureSaved: computed(() => projectData.dataSourceStructureSet && projectData.dataSourceStructureDispatch.success),
      dataSourceStructureError: computed(() => projectData.dataSourceStructureDispatch.error),
      readonly: computed(() => store.getters['autocbm/project/readonly'])
    })
    const projectActions = {}
    const project = { data: projectData, ...projectActions }

    watch(() => project.data.dataSource, dataSource => {
      handleUpdate('dataSource', dataSource)
      if (dataSource) {
        dataSources.loadSourceStructure(dataSource)
      } else {
        dataSources.clearSourceStructure()
      }
    }, { immediate: true })
    watch(() => project.data.dataSourceStructure, dataSourceStructure => {
      handleUpdate('dataSourceStructure', dataSourceStructure)
      if (!dataSourceStructure) {
        dataSources.clearSourceStructure()
      }
    }, { immediate: true })

    onMounted(() => {
      dataSources.loadSources()
    })

    return { t, dataSources, project }
  }
}
</script>
