<template>
  <ProjectLayout @cancel="returnToProjects" back="disabled" :forward="project.data.forwardStep" @forward="$emit('step', 'project_data_select')">
    <template #mainContent>
      <Message severity="info" :closable="false" class="mb-6">{{ t('autocbm.projects.management.data.load.description') }}</Message>
      <LoadDataSources />
      <Divider class="mt-5 mb-5" />
      <SelectDataTimeInterval />
    </template>
    <template v-if="project.data.displaySelectSourceItems" #detailsTitle>
      <transition name="fade" mode="out-in">
        <span class="text">{{ t('autocbm.projects.management.data.load.sources.help.title') }}</span>
      </transition>
    </template>
    <template #detailsContent>
      <transition name="fade" mode="out-in">
        <div v-if="project.data.displaySelectSourceItems" class="help-text">
          <p class="text">{{ t('autocbm.projects.management.data.load.sources.help.text') }}</p>
        </div>
        <DataSetsOverview v-else />
      </transition>
    </template>
  </ProjectLayout>
</template>

<script>
import ProjectLayout from '../../ProjectLayout'
import LoadDataSources from './LoadDataSources'
import SelectDataTimeInterval from './SelectDataTimeInterval'
import DataSetsOverview from './DataSetsOverview'

import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LoadData',
  components: { ProjectLayout, LoadDataSources, SelectDataTimeInterval, DataSetsOverview },
  setup () {
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    const projectData = reactive({
      dataSourceStructure: computed(() => store.getters['autocbm/project/dataSourceStructure']),
      measurement: computed(() => store.getters['autocbm/project/measurement']),
      displaySelectSourceItems: computed(() => !projectData.dataSourceStructure || projectData.dataSourceStructure.length === 0 || !projectData.measurement),
      startDateTime: computed(() => store.getters['autocbm/project/startDateTime']),
      endDateTime: computed(() => store.getters['autocbm/project/endDateTime']),
      forwardStep: computed(() => (!projectData.startDateTime || !projectData.endDateTime) ? 'disabled' : null)
    })
    const returnToProjects = () => router.push({ name: 'projects' })
    const project = { data: projectData }

    return { t, project, returnToProjects }
  }
}
</script>
